<template>
  <v-container>
    <LoadingBanner v-if="loading"/>
    <ErrorBanner v-else-if="!loading && devis === null"/>
    <div v-if="devis !== null">

      <v-row no-gutters justify="space-between">
        <h1 class="primary--text">
          DEVIS
        </h1>
        <v-btn text color="primary" :to="'/dossier/' + devis.dossier">
          <v-icon>mdi-arrow-left</v-icon>
          Retour au dossier
        </v-btn>
      </v-row>
      <span v-if="devis.archived" class="orange--text font-weight-bold">Ce devis est archivé.</span>
      <v-row class="my-2" justify="center" no-gutters>
        <v-col cols="12">
          <h2 class="primary--text">Type de devis</h2>
          <span>{{ types[devis.type] }}</span>


        </v-col>
      </v-row>
      <v-row no-gutters justify="space-between">
        <v-btn v-if="!devis.archived && (isIntern || isMaitreOeuvre)" @click="changeType" color="primary">
          <v-icon>mdi-cog</v-icon>
          Transformer en devis &nbsp;<span v-if="devis.type === 'normal'">importé</span> <span v-else>normal</span></v-btn>
        <div>
          <v-progress-circular indeterminate color="primary" v-if="duplicating"></v-progress-circular>
          <v-btn @click="duplicate" v-if="isIntern || isMaitreOeuvre" :disabled="duplicating" color="primary">
            <v-icon>mdi-copy</v-icon>
            Dupliquer le devis
          </v-btn>
        </div>
      </v-row>
      <v-row class="my-4" no-gutters>
        <v-col class="text-center px-2" md="3" cols="12">
          <v-card color="red" class="text-center">
            <v-card-subtitle class="ml-4 white--text">
              <h2>Nom du devis</h2>
              <!-- TODO : Remettre le nom -->
              <h2>{{ devis.name !== null ? devis.name : "Non défini"}}</h2>
            </v-card-subtitle>
          </v-card>
          <EditDevisName v-if="isIntern || isMaitreOeuvre"  :devis="devis" @reload-devis="loadDevis"/>
        </v-col>
        <v-col class="text-center px-2" md="3" cols="12">
          <v-card color="primary" class="text-center">
            <v-card-subtitle class="ml-4 white--text">
              <h2>Montant total TTC</h2>
              <h2>{{ devis.total_ttc.toFixed(2) }} €</h2>
            </v-card-subtitle>
          </v-card>
          <EditMontantDevisDialog @reload-devis="loadDevis" :devis="devis" />
        </v-col>
        <v-col class="text-center px-2" md="3" cols="12">
          <v-card color="orange" class="text-center">
            <v-card-subtitle class="ml-4 white--text">
              <h2>Date du devis</h2>
              <h2 v-if="devis.proposal_date">{{ getFormattedDate(devis.proposal_date) }}</h2>
              <h2 v-else>Non défini</h2>
            </v-card-subtitle>
          </v-card>
          <EditDateDevisDialog v-if="isIntern || isMaitreOeuvre" :devis="devis" @reload-devis="loadDevis"/>
        </v-col>
        <v-col class="text-center px-2" md="3" cols="12">
          <v-card color="blue" class="darken-2 text-center">
            <v-card-subtitle class="ml-4 white--text">
              <h2>Document du devis</h2>
              <h2 v-if="devis.proposal_file"><a class="white--text text-decoration-none" target="_blank" :href="devis.proposal_file">Voir le document</a></h2>
              <h2 v-else>Non défini</h2>
            </v-card-subtitle>
          </v-card>
          <EditFileDevisDialog v-if="isIntern || isMaitreOeuvre"  :devis="devis" @reload-devis="loadDevis"/>
          <br>
          <SignDevisForm v-if="isIntern || isMaitreOeuvre" class="mt-4" :devis="devis" @reload-devis="loadDevis"/>
          <p v-if="devis.proposal_file && devis.proposal_file.includes('sign')" class="blue--text">Devis signé, le re-signer effaçerait la signature actuelle.</p>
        </v-col>
      </v-row>
      <v-row class="my-2" justify="center" align="center">
        <p class="primary--text"><v-icon>mdi-exclamation-point</v-icon> En mode importé, entrer les opérations permet de rentrer par l'assignation des travaux.</p>
      </v-row>
      <v-simple-table v-if="devis !== null">
        <template v-slot:default>
          <thead>
          <tr>
            <th>Catégorie</th>
            <th>Code</th>
            <th>Description</th>
            <th>Unité</th>
            <th>Montant</th>
            <th>Quantité</th>
            <th style="min-width: 100px">Sous-total</th>
            <th>Observations</th>
            <th>X</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="operation in devis.operations" :key="operation.id">
            <td v-if="operation.category !== null">{{ operation.category.name }}</td>
            <td v-else>Sans catégorie</td>
            <td>{{ operation.code }}</td>
            <td>{{ operation.description }}</td>
            <td>{{ operation.unit }}</td>
            <td>{{ formatNumber(operation.price) }}</td>
            <td>{{ operation.quantity }}</td>
            <td>{{formatNumber(operation.subtotal)}}</td>
            <td>{{operation.observations}}</td>
            <td>
              <div v-if="isIntern || isMaitreOeuvre">
                <v-icon @click="deleteOperation(operation)" color="red">mdi-trash-can</v-icon>
                <EditDevisOperation @reload-devis="loadDevis" :operation="operation"/>
              </div>
            </td>
          </tr>
          <tr v-if="!devis.archived && (isIntern || isMaitreOeuvre)">
            <td>
              <v-select :items="categories" v-model="categoryModel" item-text="name" item-value="id"></v-select>
            </td>
            <td colspan="4">
              <v-autocomplete :items="filtered_referentiel" return-object item-text="referentiel_name" item-value="id"
                              label="Opération"
                              v-model="referentielModel"></v-autocomplete>
            </td>
            <td>
              <v-text-field type="number" step="1" label="Quantité" v-model="quantity"></v-text-field>
            </td>
            <td>
              <v-icon @click="addOperation" large color="primary">mdi-plus</v-icon>
            </td>
          </tr>
          <tr>
            <td colspan="4"><strong>Sous-total</strong> </td>
            <td>{{ formatNumber(total_operations_ht)}}</td>
            <td></td>
          </tr>
          <tr>
            <td colspan="3"><strong>Total</strong> </td>
            <td><strong>TVA : 2.10%</strong></td>
            <td>{{ formatNumber(total_operations_ttc) }}</td>
            <td></td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-simple-table>
        <template>
          <thead>
          <tr>
            <th>Catégorie</th>
            <th>Total</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="total in categories_total" :key="total.category">
            <td>{{ total.category }}</td>
            <td>{{formatNumber(total.total)}}</td>
          </tr>
          <tr>
            <td class="font-weight-bold">Total</td>
            <td class="font-weight-bold">{{formatNumber(total_operations_ht) }}</td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-row class="my-2" no-gutters justify="center" align="center" v-if="devis.type === 'import' && devis.operations.length > 0 && total_operations_ttc !== montantTotal">
        <p class="orange--text">Attention, le montant total du devis importé est différent du montant total des opérations rajoutés.</p>
      </v-row>
      <v-row no-gutters justify="center" align-content="center">
        <v-btn v-if="isIntern || isMaitreOeuvre" color="red" class="white--text" @click="deleteDevis">Supprimer le devis</v-btn>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import LoadingBanner from "@/components/home/LoadingBanner";
import ErrorBanner from "@/components/home/ErrorBanner";
import EditDevisOperation from "@/views/EditDevisOperation";
import EditMontantDevisDialog from "@/views/EditMontantDevisDialog";
import {DateUtilMixin} from "@/mixins/DateUtilMixin";
import EditDateDevisDialog from "@/views/EditDateDevisDialog";
import EditFileDevisDialog from "@/views/EditFileDevisDialog";
import SignDevisForm from "@/components/devis/SignDevisForm.vue";
import EditDevisName from "@/views/EditDevisName.vue";
import {AuthMixin} from "@/mixins/AuthMixin";

export default {
  name: "DevisView",
  components: {
    EditDevisName,
    EditFileDevisDialog,
    EditDateDevisDialog, EditMontantDevisDialog, EditDevisOperation, LoadingBanner, ErrorBanner, SignDevisForm},
  mixins: [DateUtilMixin, AuthMixin],
  async mounted() {
    this.loadDevis();
    if (this.referentiel.length === 0) {
      await this.$store.dispatch("referentiel/fetchReferentiel");
    }
    if (this.$store.getters["category/categories"].length === 0) {
      await this.$store.dispatch("category/fetchCategory");
    }
  },
  data() {
    return {
      loading: false,
      referentielModel: null,
      categoryModel: null,
      quantity: 1,
      devis: null,
      duplicating: false,
      types: {
        "normal": "Normal",
        "import": "Importé",
      },
      montantTotal: 0.0,
    }
  },
  computed: {
    referentiel() {
      return this.$store.getters["referentiel/referentiel"];
    },
    categories() {
      let categories = this.$store.getters["category/categories"];
      categories = categories.filter(c => c.archived === false)
      return [{id: null, name: "Toutes les catégories"}, ...categories];
    },
    filtered_referentiel: function () {
      return this.referentiel.filter((item) => {
        if (this.categoryModel !== null) {
          return item.category?.id === this.categoryModel;
        } else {
          return true;
        }
      });
    },
    total_operations_ht() {
      let total = this.devis.operations.map((operation) => operation.quantity * operation.price).reduce((a, b ) => a + b, 0);
      return Math.round(total * 100) / 100;
    },
    total_operations_ttc() {
      let total = this.devis.operations.map((operation) => operation.quantity * operation.price).reduce((a, b ) => a + b, 0);
      return Math.round(total * 1.021 * 100) / 100;
    },
    categories_total() {
      let categories_totals = [];
      let categories = new Set(this.devis.operations.map(operation => operation.category?.name ?? "Non défini"));
      for(const category of categories) {
        let total = {
          category: category,
          total: 0,
        };
        let operations_of_category = this.devis.operations.filter(operation => (operation.category?.name ?? "Non défini") === category);
        for(const operation of operations_of_category) {
          total.total += operation.price * operation.quantity;
        }
        categories_totals.push(total);
      }
      return categories_totals;
    }
  },
  methods: {
    async addOperation() {
      let operation = {
        "devis": null,
        "category": null,
        "description": "",
        "code": "",
        "unit": "",
        "price": null,
        "quantity": 1,
      };
      operation.devis = this.devis.id;
      operation.category = this.referentielModel.category?.id;
      operation.description = this.referentielModel.description;
      operation.code = this.referentielModel.code;
      operation.unit = this.referentielModel.unit;
      operation.price = this.referentielModel.price;
      operation.quantity = this.quantity;
      let newOperation = await this.$store.dispatch("operations/postOperation", operation);
      if (newOperation !== null) {
        this.referentielModel = null;
        this.loadDevis();
        this.$store.dispatch("annonce/annonceSuccess", "Opération ajouté au devis");
      }
    },
    async deleteOperation(operation) {
      if (operation.id === undefined) {
        console.log("Pas d'ID");
        return;
      }
      let result = await this.$store.dispatch("operations/deleteOperation", operation);
      if (result === "success") {
        await this.loadDevis();
        this.$store.dispatch("annonce/annonceSuccess", "Opération retiré au devis");
      }
    },
    async deleteDevis() {
      let result = await this.$store.dispatch("devis/deleteDevis", this.devis);
      if (result === "success") {
        await this.$router.push(`/dossier/${this.devis.dossier}`);
        this.$store.dispatch("annonce/annonceSuccess", "Devis supprimé");
      }
    },
    async loadDevis() {
      this.loading = true;
      let devisId = this.$route.params?.id;
      this.devis = await this.$store.dispatch("devis/getDevisById", {id: devisId});
      this.montantTotal = this.devis.total;
      this.loading = false;
    },
    async changeType() {
      let type = this.devis.type === "normal" ? "import" : "normal";
      this.loading = true;
      let devis = await this.$store.dispatch("devis/patchDevis", {id: this.devis.id, type})
      if (devis !== undefined) {
        this.devis = devis;
        this.montantTotal = this.devis.total;
        this.$store.dispatch("annonce/annonceSuccess", "Devis mis à jour.");
      }
      this.loading = false;
    },
    async duplicate() {
      this.duplicating = true;
      let newDevis = await this.$store.dispatch("devis/duplicate", {id: this.devis.id});
      this.duplicating = false;
      if(newDevis) {
        this.$store.dispatch("annonce/annonceSuccess", "Devis dupliqué !");
        this.$router.push(`/devis/${newDevis.id}`);
      }
    },
    formatNumber(value) {
      return Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(value);
    }
  }
}
</script>

